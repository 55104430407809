<template>
	<div
		class="wrap__content"
		v-bind:class="{ 'wrap__content--preloader': !permissionsList && !moduleList }"
	>
		<v-preloader v-if="!permissionsList && !moduleList" :message="errorMessage"></v-preloader>

		<div class="container" v-if="permissionsList && moduleList">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ReferenceManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">{{ $t('titles.creating_handbooks') }}</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<form method="POST" @submit.prevent="onSubmit">
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('placeholder.name') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.name
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															required=""
															name="name"
															value=""
															:placeholder="$t('placeholder.name')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.name"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.name"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('placeholder.table_column') }}</div>
										</div>
										<div class="form__line--right">
											<div
												class="form__line form__line--children"
												v-for="(item, index) in fildsList"
												:key="index"
											>
												<div class="row align-items-center">
													<div class="col-xl-5">
														<div class="form__line--input">
															<input
																type="text"
																class="input-linck"
																:name="'columns[' + index + ']'"
																v-model="fildsList[index].name"
																:placeholder="$t('placeholder.adds_field')"
															/>
														</div>
													</div>
													<div class="col-xl-2">
														<div class="input__block--add">
															<a
																@click="deleteFild(index)"
																v-tooltip.right="{
																	content: $t('commands.remove_field'),
																	class: ['tooltip__btn'],
																}"
															>
																<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
															</a>
														</div>
													</div>
												</div>
											</div>
											<div class="form__line form__line--children">
												<div class="row align-items-center">
													<div class="col-xl-5">
														<div class="form__line--input">
															<input
																type="text"
																class="input-linck"
																@keyup.enter="addFild(fildsIndex)"
																:name="'filds[' + fildsIndex + 1 + ']'"
																v-model="fildsNew"
																:placeholder="$t('placeholder.adds_field')"
															/>
														</div>
													</div>
													<div class="col-xl-2">
														<div class="input__block--add">
															<a
																@click="addFild(fildsIndex)"
																v-tooltip.right="{
																	content: $t('commands.add_field'),
																	class: ['tooltip__btn'],
																}"
															>
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM12 8v8M8 12h8" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('labels.use') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__checkbox--block">
														<label
															v-for="(element, elementInd) in moduleList"
															:key="elementInd"
															class="form__checkbox--line"
														>
															<input type="checkbox" name="modules[]" :value="element.value" />
															<div class="checkbox__item">
																<span class="form__checkbox--text">
																	{{ $t('modules_list.' + element.name) }}
																</span>
																<span class="form__checkbox--check"></span>
															</div>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left"></div>
										<div class="form__line--right">
											<div
												class="permission__block"
												:class="[
													errorMessage.messages && errorMessage.messages.roles
														? 'input--error'
														: '',
												]"
											>
												<div class="row">
													<div class="col-xl-5">
														<div class="permission__block--add">
															<a @click="$modal.show('modal_permission')">
																{{ $t('headers.role') }}
																<img src="../../../assets/img/icon-plus-blue.svg" />
															</a>
														</div>
														<div class="permission__block--list">
															<a
																class="permission__block--item"
																v-for="(permission, key) in permissions"
																:key="key"
															>
																{{ $t('roles.' + permission.description) }}
																<span class="permission__close" @click="permissionClick(key)">
																	<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.5 13.75a6.25 6.25 0 100-12.5 6.25 6.25 0 000 12.5zM9.375 5.625l-3.75 3.75M5.625 5.625l3.75 3.75" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
																</span>
															</a>
														</div>
													</div>
												</div>

												<modal
													class="modal__block modal__block--middle modal__permission"
													name="modal_permission"
													:width="520"
													:min-width="160"
													height="auto"
												>
													<div class="modal__block--wrapper">
														<div
															class="modal__block--close"
															@click="$modal.hide('modal_monitoring')"
														>
															<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
														</div>
														<div class="modal__wrapper">
															<div class="modal__content">
																<div class="modal__form">
																	<ul class="modal__permission--list">
																		<li v-for="(permission, key) in permissionsList" :key="key">
																			<a @click="permissionAdd(key)">{{
																				$t('roles.' + permission.description)
																			}}</a>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</modal>
											</div>
											<div
												class="input-required"
												v-if="errorMessage.messages && errorMessage.messages.roles"
											>
												<p
													v-for="(massage, massageInd) in errorMessage.messages.roles"
													:key="massageInd"
												>
													{{ massage }}
												</p>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left"></div>
										<div class="form__line--right">
											<div
												class="form__btn--line"
												v-if="$store.getters.userPermission(['change_catalog'])"
											>
												<div class="input__row">
													<button class="input-btn form__line--btn">
														{{ $t('button.save') }}
													</button>
												</div>
											</div>

											<div
												class="form__block--line form__messages"
												v-if="message.status"
												:class="{
													'form__messages--error': message.status != 200,
													'form__messages--success': message.status == 200,
												}"
											>
												<label class="form__block--input">
													<span class="form__block--title">
														{{ message.text }}
													</span>
												</label>
											</div>
											<div
												class="form__block--line form__messages"
												v-if="errorMessage.text"
												:class="{
													'form__messages--error': errorMessage.status != 200,
													'form__messages--success': errorMessage.status == 200,
												}"
											>
												<label class="form__block--input">
													<span class="form__block--title">
														{{ errorMessage.text }}
													</span>
												</label>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { api } from '@/boot/axios'

export default {
	components: {
		Multiselect,
	},
	data() {
		return {
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			fildsNew: '',
			fildsList: [],
			fildsIndex: 0,

			moduleList: null,
			moduleSelect: null,

			permissionsList: null,
			permissionsSelect: [],
			permissions: [],
		}
	},
	methods: {
		permissionClick(key) {
			this.permissions.splice(key, 1)
			this.permissionsSelect.splice(key, 1)
		},
		permissionAdd(element) {
			if (this.permissionsSelect.indexOf(this.permissionsList[element].id) == -1) {
				this.permissions.push(this.permissionsList[element])
				this.permissionsSelect.push(this.permissionsList[element].id)
			}
		},
		addFild(index) {
			if (this.fildsNew != '') {
				this.fildsList.push({ name: this.fildsNew })
				this.fildsIndex = this.fildsList.length
				this.fildsNew = ''
			}
		},
		deleteFild(index) {
			this.fildsList.splice(index, 1)
			this.fildsIndex = this.fildsList.length
		},
		onSubmit(evt) {
			this.errorMessage = {
				status: null,
				messages: null,
				text: null,
			}
			this.message = {
				status: null,
				text: null,
			}
			const formData = new FormData(evt.target)
			// formData.append('accesses', JSON.stringify(this.permissions));
			// formData.append('accesses[]', JSON.stringify(this.permissions));
			for (var i = 0; i < this.permissionsSelect.length; i++) {
				formData.append('roles[' + i + ']', this.permissionsSelect[i])
			}
			if (this.fildsNew != '') {
				formData.append('columns[' + this.fildsList.length + ']', this.fildsNew)
			}
			api
				.post('catalog/create', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message

						this.$router.push({ path: '/' + this.$i18n.locale + '/account/references' })
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					this.errorMessage.messages = error.response.data.error_messages
				})
		},

		apiGetRoleList() {
			api
				.get('user/role/list')
				.then((response) => {
					if (response.data) {
						this.permissionsList = response.data
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
		apiGetModuleList() {
			api.get('catalog/module/list').then((response) => {
				if (response.data) {
					this.moduleList = response.data
				}
			})
		},
	},
	beforeMount() {
		this.apiGetRoleList()
		this.apiGetModuleList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>

<style scoped></style>
